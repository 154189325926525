import { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import API from "../utils/API";

function ConstructionSciencesBilling() {
  const [workorders, setWorkorders] = useState();

  useEffect(() => {
    API.get(`/portal/construction_sciences/52775cb2-3a9e-4860-b829-2f2be43b1139`, { params: { page: 0, limit: 250 } })
      .then((r) => {
        setWorkorders(r.data);
      })
      .catch((e) => console.log(e));
  }, []);

  return workorders ? (
    <div className="page-container">
      <div className="page-header">
        <div className="page-header-container">Construction Sciences Billing ({workorders && workorders.length})</div>
      </div>

      <div className="cs-billing-table-container">
        <table className="cs-billing-table">
          <thead>
            <tr>
              <th>Date</th>
              <th>CMD#</th>
              <th>Location</th>
              <th>Items</th>
              <th>Field Sheet</th>
            </tr>
          </thead>
          <tbody>
            {workorders.map((wo) => (
              <tr
                key={wo.id}
                style={{
                  backgroundColor: wo.field_complete ? "#f8fff8" : "#fff8f2",
                }}
                className="cs-billing-row"
              >
                <td className="cs-date-cell">
                  <div className="cs-date">{format(parseISO(wo.work_date), "dd MMM Y")}</div>
                  <div className="cs-time">
                    {wo.work_start_time ? format(parseISO(wo.work_start_time.split("Z")[0]), "HH:mm") : "--"} -
                    {wo.work_finish_time ? format(parseISO(wo.work_finish_time.split("Z")[0]), "HH:mm") : "--"}
                  </div>
                </td>
                <td className="cs-cmd-cell">{wo.billing_customer_ref || "--"}</td>
                <td className="cs-location-cell">
                  <div className="cs-customer">{wo.field_customer}</div>
                  <div className="cs-address">{wo.field_address_line_1}</div>
                  <div className="cs-address">{wo.field_address_line_2}</div>
                </td>
                <td className="cs-items-cell">
                  {wo.line_items.map((item, index) => (
                    <div key={index} className="cs-item">
                      <span className="cs-item-name">{item.product_name}:</span>
                      <span className="cs-item-units">{item.units}</span>
                    </div>
                  ))}
                </td>
                <td className="cs-report-cell">
                  {wo.report_url && (
                    <a href={wo.report_url} target="_blank" rel="noreferrer" className="cs-report-link">
                      {wo.report_file_name || "View Report"}
                    </a>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  ) : (
    "Loading..."
  );
}

export default ConstructionSciencesBilling;

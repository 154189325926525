import "./css/app.scss";

import { Routes, Route } from "react-router-dom";
import ProjectReports from "./pages/ReportsProject";
import ConstructionSciencesBilling from "./pages/ConstructionSciencesBilling";
import ClientReports from "./pages/ReportsClient";

function App() {
  return (
    <div className="main-container">
      <Routes>
        <Route path="/" index={true} element={<b>Error</b>} />
        <Route path="/p" index={true} element={<ProjectReports />} />
        <Route path="/c" index={true} element={<ClientReports />} />
        <Route path="/construction_sciences" index={true} element={<ConstructionSciencesBilling />} />
      </Routes>
    </div>
  );
}

export default App;
